import { configureStore  } from '@reduxjs/toolkit';
import HeaderSlice, { HeaderSliceName } from './header-slice';
import LoaderSlice, { LoaderSliceName } from './loader-slice';
import AlertSlice, { AlertSliceName } from './alert-slice';

const store = configureStore({
    reducer: {
        [HeaderSliceName]   : HeaderSlice.reducer,
        [LoaderSliceName]   : LoaderSlice.reducer,
        [AlertSliceName]    : AlertSlice.reducer,
    }
});

export default store;