function cssNameSimplifier(toConvert){
    const camelize = s => s.replace(/-./g, x=>x[1].toUpperCase());
    
    const styles = {};

    Object.keys(toConvert).forEach( styleName => {
        styles[camelize(styleName)] = toConvert[styleName];
    })

    return styles;
}

export default cssNameSimplifier;