// Functional Imports
import React, { useEffect, useState } from 'react';
import cssNameSimplifier from "../../utility/css-name-simplifier";
import isEmpty from '../../utility/is-empty';
import { HeaderSliceName } from '../../store/header-slice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Component imports
import { Tabs, Tab, tabsClasses, AppBar, Box } from "@mui/material";

// Styles Imports
import importedStyles from './AppsBar.module.css';

const styles = cssNameSimplifier(importedStyles);

function AppsBar(props) {

    const currentLocation = useLocation();
    // const employeeRole = useSelector(state => state[HeaderSliceName].employee.role )
    const tabs = useSelector(state => state[HeaderSliceName].appTabs);
    const [ currentTab, setCurrentTab ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const index = tabs.findIndex(tab => currentLocation.pathname.endsWith(tab.endpoint) );
        setCurrentTab((index > -1)? index : false);
    }, [tabs, currentLocation.pathname]);

    return (
        !isEmpty(tabs) && 
        <Box
            sx={{
                flexGrow: 1,
                // maxWidth: { xs: 320, sm: 480 },
                width: '100vw',
                bgcolor: 'background.paper',
            }}
        >
            <AppBar position="static" sx={{ background: 'var(--primary-color)' }}>
                <Tabs
                    value={currentTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                        maxWidth: '2200px',
                        margin: 'auto'
                    }}
                >
                    {tabs.map( tab => 
                        <Tab 
                            key={tab.endpoint} 
                            className={styles.tab} 
                            label={tab.name} 
                            onClick={() => {navigate(`${tab.baseURL}/${tab.endpoint}`, { relative: 'route' })}}
                        />
                    )}
                </Tabs>
            </AppBar>
        </Box>  
    )
}

export default AppsBar;
