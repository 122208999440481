import { createSlice } from "@reduxjs/toolkit";

const LoaderSlice = createSlice({
    name: 'loading',
    initialState: { displayLoader: false, loadersList: {} },
    reducers: {
        setDisplayLoader( state, action ){
            const displayState = action.payload.state;
            const from = action.payload.from;
            
            if(!displayState) delete state.loadersList[from];
            else state.loadersList[from] = displayState;

            state.displayLoader = Object.keys(state.loadersList).length > 0;
        }
    }
});

export const LoaderSliceActions = LoaderSlice.actions;
export const LoaderSliceName = LoaderSlice.name;
export default LoaderSlice;