export const HOST_URL = 'https://backend.concorde.in:8080';

export const BOOKING_FORM_URL = '/booking-form'; 

export const PROJECTS_URL = '/projects';

export const SOURCE_URL = '/source';

export const CHANNEL_PARTNER_URL = '/channel-partner';

export const PROFILE_URL = '/profile';

export const APPS_URL = '/apps';

export const AUTH_URL = '/auth';