import { createSlice } from "@reduxjs/toolkit";

const AlertSlice = createSlice({
    name: 'alert',
    initialState: { showAlert: false, message: '', severity: 'success', title: '', autoclose: true },
    reducers: {
        showAlert( state, action ){
            state.showAlert = true;
            state.message   = action.payload.message;
            state.title     = action.payload.title || '';
            state.severity  = action.payload.severity || state.severity;
            state.autoclose = (action.payload.autoclose === undefined)? state.autoclose : action.payload.autoclose;
        },
        closeAlert(state, action){
            state.showAlert = false;
            state.message = '';
            state.title = '';
            state.autoclose = true;
        }
    }
});

export const AlertSliceActions = AlertSlice.actions;
export const AlertSliceName = AlertSlice.name;
export default AlertSlice;