import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "../utility/is-empty";

const HeaderSlice = createSlice({
    name: 'header',
    initialState: {
        currentApp: '',
        apps: [],
        appTabs: [],
        appPermissions: {},
        employee: {
            id: '',
            name: '',
            role: ''
        }
    },
    reducers: {
        setApps( state, action ){
            state.apps = action.payload ?? [];
            // state.appTabs = [];
        },
        setHeaders( state, action ){
            if(isEmpty( action.payload?.currentApp )) throw new Error('Current App Name can not be empty!');
            state.currentApp = action.payload.currentApp;
            state.appTabs = action.payload.appTabs ?? [];
            state.appPermissions = action.payload.appPermissions ?? {};
        },
        setUserDetails( state, action ){
            state.employee.id = action.payload.employeeId;
            state.employee.name = action.payload.employeeName;
            state.employee.role = action.payload.employeeRole;
        }
    }   
});

export default HeaderSlice;
export const HeaderSliceName = HeaderSlice.name;
export const HeaderSliceActions = HeaderSlice.actions;