// Functional Imports
import React, { useState, useReducer } from "react";
import ReactDOM from "react-dom";
import cssNameSimplifier from "../../utility/css-name-simplifier";
import { useSelector } from "react-redux";
import { HeaderSliceName } from "../../store/header-slice";
import { useHttpClientWithNavigate } from "../../utility/http-hook";
import { useNavigate } from "react-router-dom";

// Component imports
// import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import MenuDrawer from "./MenuDrawer";
import AppsBar from "./AppsBar";
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip, IconButton, Avatar, Menu, MenuItem, ListItemIcon, Divider, ClickAwayListener } from "@mui/material";


// Styles Imports
import importedStyles from './Header.module.css';
import concordeLogo from '../../Assets/Img/logo.svg';
import concordeLogoSm from '../../Assets/Img/logo-sm.svg';
// import concordeLogo from '../../Assets/Img/c-logo.png'

const styles = cssNameSimplifier(importedStyles);

function avatarMenuReducer(state, action) {
    if (action?.anchor) {
        return { menuAnchor: action.anchor, display: true };
    }

    return { menuAnchor: null, display: false };
}

function NavBar(props) {
    const [avatarMenuState, dispatchAvatarMenuState] = useReducer(avatarMenuReducer, { menuAnchor: null, display: false });
    const [menuState, setMenuState] = useState(false);  
    const currentApp = useSelector(state => state[HeaderSliceName].currentApp);
    const employeeName = useSelector(state => state[HeaderSliceName].employee.name)?.trim().split(' ');
    const sendRequest = useHttpClientWithNavigate();
    const navigate = useNavigate();

    let avatarName = '';
    if (employeeName?.length > 1) {
        avatarName = employeeName[0].charAt(0).toUpperCase() + employeeName[1].charAt(0).toUpperCase()
    } else {
        avatarName = employeeName[0].charAt(0).toUpperCase();
    }

    function myAccountClickHandler(){
        dispatchAvatarMenuState();
        navigate('/profile');
    }

    function navigateToHome(){
        dispatchAvatarMenuState();
        navigate('/');
    }

    async function logOut() {
        await sendRequest({ url: '/auth/logout', method: 'DELETE', showLoader: true });
    }

    return (
        <>
            <main className={styles.headerContainer}>
                <MenuDrawer open={menuState} closeMenu={() => setMenuState(false)} />
                
                <nav className={styles.navBar}>
                    <div className={styles.navConatiner}>
                        <div>
                            <div className={styles.appDrawer} onClick={() => setMenuState(true)}>
                                {/* <AppsIcon /> */}
                                <Tooltip title='Apps'>
                                    <MenuIcon />
                                </Tooltip>
                            </div>
                            <div className={styles.logo}>
                                <img src={concordeLogo} alt="Concorde Logo" />
                                <img src={concordeLogoSm} alt="Concorde Logo" />
                            </div>
                            <div className={styles.currentApp}>
                                <Tooltip title="Current App">
                                    <p>{currentApp}</p>
                                </Tooltip>
                            </div>
                        </div>

                        <ClickAwayListener onClickAway={() => dispatchAvatarMenuState()}>
                            <div>
                                <Tooltip title="Profile">
                                    <IconButton
                                        onClick={(e) => dispatchAvatarMenuState({ anchor: e.currentTarget })}
                                        size="small"
                                        aria-haspopup="true"
                                    >
                                        <Avatar>{avatarName}</Avatar>
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    anchorEl={avatarMenuState.menuAnchor}
                                    id="account-menu"
                                    open={avatarMenuState.display}
                                    onClose={() => dispatchAvatarMenuState()}
                                    onClick={() => dispatchAvatarMenuState()}
                                    elevation={0}
                                    sx={{
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '& .MuiPaper-root': {
                                            backgroundColor: '#303030'
                                        }, 
                                        '& .MuiList-root .MuiMenuItem-root:first-of-type:hover': {
                                            cursor: "default",
                                            backgroundColor: 'transparent'
                                        },
                                        maxWidth: 300
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={navigateToHome}>
                                        <p 
                                            style={{ 
                                                whiteSpace: 'nowrap', 
                                                width: '100%', 
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            Hello, {employeeName.join(' ')}
                                        </p>
                                    </MenuItem>
                                    <Divider variant="middle" />
                                    <MenuItem onClick={myAccountClickHandler}>
                                        <ListItemIcon>
                                            <PersonIcon fontSize="medium" />
                                        </ListItemIcon>
                                        My Account
                                    </MenuItem>
                                    <MenuItem onClick={logOut}>
                                        <ListItemIcon>
                                            <Logout fontSize="medium" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </div>
                        </ClickAwayListener>
                    </div>
                </nav>

                <AppsBar />
            </main>
        </>
    );
}

function Header(props) {
    return ReactDOM.createPortal(<NavBar {...props} />, document.getElementById('navbar'));
}

export default Header;