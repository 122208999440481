// Functional Imports
import { lazy, Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useHttpClientWithRedirect } from './utility/http-hook';

// Component Imports
import Error from './componets/UI/Error/Error';
import Loading from './componets/Loading/Loading';

// Styled Imports
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import RootPage, { fetchBasicInformation } from './pages/RootPage';

// Pages Import
const LoginPage             = lazy(() => import('./pages/LoginPage'));
const BookingFormPages      = lazy(() => import('./pages/BookingFormPages'));
const HomePages             = lazy(() => import('./pages/HomePages'));
const AccountSettingsPages  = lazy(() => import('./pages/AccountSettingsPages'));
const SourcePages           = lazy(() => import('./pages/SourcePages'));
const ChannelPartnerPages   = lazy(() => import('./pages/ChannelPartnerPages'));
const ProjectPages          = lazy(() => import('./pages/ProjectPages'));

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function App() {
    const sendRequest = useHttpClientWithRedirect();

    const ROUTES = createBrowserRouter([
        { 
            path: '/', 
            element: <RootPage />,
            loader: ({ params, request }) => fetchBasicInformation({ params, request, sendRequest }),
            errorElement: <Error status='500' msg='Internal Server Error' />, 
            children: [
                { 
                    index: true, 
                    element: (
                        <Suspense fallback={<Loading fallback />}>
                            <HomePages />  
                        </Suspense>
                    )
                }, 
                { 
                    path: '/booking-form/*', 
                    element: (
                        <Suspense fallback={<Loading fallback />}>
                            <BookingFormPages />
                        </Suspense> 
                    )       
                }, 
                { 
                    path: '/source/*', 
                    element: (
                        <Suspense fallback={<Loading fallback />}>
                            <SourcePages /> 
                        </Suspense>
                    )
                }, 
                { 
                    path: '/channel-partner/*', 
                    element: (
                        <Suspense fallback={<Loading fallback />}>
                            <ChannelPartnerPages /> 
                        </Suspense>
                    )
                }, 
                { 
                    path: '/projects/*', 
                    element: (
                        <Suspense fallback={<Loading fallback />}>
                            <ProjectPages /> 
                        </Suspense>
                    )
                }, 
                { 
                    path: '/profile/*', 
                    element: (
                        <Suspense fallback={<Loading fallback />}>
                            <AccountSettingsPages />
                        </Suspense>
                    )
                }
            ],
        }, 
        { 
            path: 'auth/*', 
            element: (
                <Suspense fallback={<Loading fallback />}>
                    <LoginPage />
                </Suspense>
            ),
            errorElement: <Error status='500' msg='Internal Server Error' /> 
        },
        { 
            path: 'error', 
            element: <Error /> 
        }
    ]);


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <RouterProvider router={ROUTES} />
        </ThemeProvider>
    );
}

export default App;
