// Functional Imports
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LoaderSliceActions } from '../store/loader-slice';
import { useNavigate, redirect } from 'react-router-dom';
import { HOST_URL } from './base-urls';

function useHttpClientWithNavigate(){
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    
    function displayLoader(state){
        dispatcher( LoaderSliceActions.setDisplayLoader(state) );
    }

    const sendRequest = useCallback( async ({ url, method = 'GET', body = null, headers = {}, replace = false, timeout = 0, showLoader = false}) => {
        try {
                url = HOST_URL + url;
            
                if(showLoader){
                    displayLoader({ from: url, state: true });
                }

                const response = await fetch(url, {method, body, headers, credentials: 'include'} );
                
                if( response.status === 401 ){
                    navigate('/auth', { relative: 'route', replace });

                    if(showLoader){
                        displayLoader({ from: url, state: false });
                    }

                    return;
                }

                if(response.redirected){
                    const redirectUr1 = new URL (response.url);   
                    
                    navigate( redirectUr1.pathname, { replace });
                    
                    if(showLoader){
                        displayLoader({ from: url, state: false });
                    }

                    return
                }

                if(response.status >= 500 && !response.headers.get('content-type').startsWith('application/json')){
                    throw response;
                }

                const responseData = response.headers.get('content-type').startsWith('application/json')? await response.json() : {};

                if(responseData.redirected && response.status >= 300 && response.status <= 400){
                    navigate(responseData.url, { replace });
                    
                    if(showLoader){
                        displayLoader({ from: url, state: false });
                    }

                    return;
                }   


                if( response.status === 403 ){
                    navigate('/error', { replace: true, state: { status: 403, msg: responseData?.msg || 'You are not autorized to access ' + url } });
                    
                    if(showLoader){
                        displayLoader({ from: url, state: false });
                    }

                    return;
                }
                
                if (!response.ok) {
                    const error = { ...responseData, status: response.status }
                    throw error;
                }
                
                if(showLoader){
                    setTimeout( () => displayLoader({ from: url, state: false }), timeout * 1000 );
                }
                
                return response.headers.get('content-type').startsWith('application/json')? responseData : response;
            } catch (err) {
                if(showLoader){
                    displayLoader({ from: url, state: false });
                }

                throw err;             
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return sendRequest;
};

function useHttpClientWithRedirect(){
    const dispatcher = useDispatch();
    
    function displayLoader(state){
        dispatcher( LoaderSliceActions.setDisplayLoader(state) );
    }

    const sendRequest = useCallback( async ({ url, method = 'GET', body = null, headers = {}, timeout = 0, showLoader = true }) => {
        try {
                url = HOST_URL + url;

                if(showLoader){
                    displayLoader({ from: url, state: true });
                }

                const response = await fetch(url, {method, body, headers, credentials: 'include'} );

                if(response.status === 401){
                    if(showLoader){
                        displayLoader({ from: url, state: false });
                    }

                    return redirect('/auth');
                }

                if( response.status >= 500 ){
                    let jsonResponse;
                    try{
                        jsonResponse = await response.json();
                    } catch ( err ){
                        throw response
                    }
                    throw jsonResponse;
                }
                
                if( response.redirected ){
                    const redirectUrl = new URL(response.url);
                    
                    if(showLoader){
                        displayLoader({ from: url, state: false });
                    }

                    return redirect(redirectUrl.pathname);
                }

                const responseData = await response.json();

                
                if (!response.ok) {
                    const error = { ...responseData, status: response.status };
                    throw error;
                }

                if(showLoader){
                    setTimeout( () => displayLoader({ from: url, state: false }), timeout * 1000 );
                }

                return responseData;
            } catch (err) {
                if(showLoader){
                    displayLoader({ from: url, state: false });
                }
                
                throw err;                
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return sendRequest;
}

export{ useHttpClientWithNavigate, useHttpClientWithRedirect };