// Functional Imports
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import { useEffect } from "react";


// Components Import
import { Container, Box } from "@mui/material";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { red } from "@mui/material/colors";


function Error(props){
    const routeError = useRouteError();
    const navigateError = useLocation();
    const navigate = useNavigate();

    const error = (routeError)? routeError : navigateError.state;

    window.history.replaceState({}, "");

    useEffect(() => {
        if(!error){
            navigate('/', { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(!error){
        return <></>
    }

    const statusCode = (error.status === 200)? 404 : error.status || props.status; 
    let statusText = error.data || error.statusText || error.msg || props.msg || 'Page Not Found!🔍';

    return (
        <Container>
            <Box sx={{height: '100vh', textAlign: 'center', position: 'relative' }}>
                <Container elevation={8} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: 2}}>
                    <ReportProblemIcon sx={{ fontSize: '3rem', color: red[500] }} />
                    <h4>{statusCode}</h4>
                    <br />
                    {
                        statusText.split('\n').map((text, index) => <>
                            <h2 key={++index}>{text}</h2>
                        </>)
                    }
                </Container>
            </Box>
        </Container>
    );
}

export default Error;