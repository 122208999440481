// Functional Imports
import React, { useState } from "react";
import cssNameSimplifier from "../../utility/css-name-simplifier";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { HeaderSliceName } from "../../store/header-slice";
import isEmpty from "../../utility/is-empty";
import { useNavigate } from "react-router-dom";

// Component imports
import { Drawer, Box, List, ListItem, Divider, TextField } from "@mui/material";

// Styles Imports
import importedStyles from './MenuDrawer.module.css';

const styles = cssNameSimplifier(importedStyles);

function MenuDrawer(props) {
    const navigate = useNavigate();
    const [ searchTerm, setSearchTerm ] = useState('');
    const appsList = useSelector(state => state[HeaderSliceName].apps);
    

    const filteredApplist = appsList.filter(app => {
        if( searchTerm ){
            return app.appName.toLowerCase().startsWith(searchTerm.toLowerCase())
        }
        return true;
    });

    function handelAppClick(href){
        href = (href !== '/')? href : '';
        navigate(`/${href}`); 
        props.closeMenu();
    }

    const appsToDisplay = filteredApplist.map( app => (
        <ListItem className={styles.apps} key={app.endpoint} onClick={() => handelAppClick(app.endpoint)}>
            <h4>{app.appName}</h4>
        </ListItem>
    ));

    return (
        <Drawer anchor='left' open={props.open} onClose={props.closeMenu} >
            <Box sx={{ width: 300 }} role="presentation">
                <List className={styles.itemsList}>
                    <ListItem>
                        <h3>Apps List</h3>
                    </ListItem>

                    <Divider variant="middle" />
                    
                    { !isEmpty(appsList) && 
                        <ListItem>
                            <TextField 
                                label="Search Apps" 
                                inputProps={{type: 'search', defaultValue: searchTerm}} 
                                sx={{ marginTop: '8px', width: '100%' }} 
                                variant="outlined" 
                                onChange={ event => { setSearchTerm(event.target.value) } }
                            />
                        </ListItem>
                    }

                    { !isEmpty(appsList) && appsToDisplay }

                    { isEmpty(appsList) && 
                        <ListItem className={styles.noApps}> 
                            <p>No Apps Found</p>
                        </ListItem>
                    }

                </List>
            </Box>
        </Drawer>
    );
}

export default MenuDrawer;