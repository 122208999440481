// Functional Imports
import { Outlet, useLoaderData, json } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HeaderSliceActions } from '../store/header-slice';

// Componet Imports
import Header from '../componets/Header/Header';
import { useEffect } from 'react';

// Styled Imports

function RootPage(){
    const appsAndUserData = useLoaderData();
    const dispatcher = useDispatch();

    useEffect(() => {
        const appsList = [...appsAndUserData.appsList, { appName: 'Home', endpoint: '/' }];

        dispatcher( HeaderSliceActions.setApps(appsList) );

        dispatcher( 
            HeaderSliceActions.setUserDetails({ 
                employeeName: appsAndUserData.name, 
                employeeRole: appsAndUserData.Role.name ,
                employeeId: appsAndUserData.id
            }) 
        );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Header />
            <Outlet />
        </>
    );
}

async function fetchBasicInformation({ params, request, sendRequest }){
    try{
        const showLoader = true;
        const appData = await sendRequest({ url: '/apps/allowed-apps', showLoader, timeout: 1 });
        const userData = await sendRequest({ url: '/profile/info', showLoader, timeout: 1 });

        if(appData instanceof Response){
            return appData;
        }

        if (userData instanceof Response){
            return userData;
        }

        return { ...appData, ...userData };
    } catch( err ){
        throw new json(err.msg || 'Internal Server Error!', { status: err.status || 500 });
    }
}


export default RootPage;
export { fetchBasicInformation }