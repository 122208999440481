// Functional imports
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { AlertSliceName, AlertSliceActions } from '../../store/alert-slice';
import cssNameSimplifier from '../../utility/css-name-simplifier';

// Component Imports
import { Alert, Box, Collapse, IconButton, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Styled Imports
import importedStyles from './Alerts.module.css';

const styles = cssNameSimplifier(importedStyles);


function ShowAlert() {

    const alert = useSelector(state => state[AlertSliceName]);
    const dispatcher = useDispatch();
    let autoCloseTimer;
    
    function closeAlert(){
        dispatcher( AlertSliceActions.closeAlert() )
    }
    
    if(alert.autoclose && alert.showAlert){
        autoCloseTimer = setTimeout(() => { 
            clearTimeout(autoCloseTimer);
            closeAlert();
        }, 8000);
    }

    return (
        <Box className={styles.alertContainer} zIndex={10000}>
            <Collapse in={alert.showAlert}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="medium"
                            onClick={closeAlert}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    variant='filled'
                    severity={alert.severity}
                    color={alert.severity || ''}
                >
                    <AlertTitle>
                        <strong>
                            { alert.title || alert.severity.charAt(0).toUpperCase() + alert.severity.slice(1) }
                        </strong>
                    </AlertTitle>
                    {alert.message}
                </Alert>
            </Collapse>
        </Box>
    );
}

function Alerts(props) {
    return ReactDOM.createPortal(<ShowAlert />, document.getElementById('alert'));
}

export default Alerts;