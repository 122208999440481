import { useSelector } from 'react-redux';
import ReactDOM  from 'react-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { LoaderSliceName } from '../../store/loader-slice';
import { useEffect } from 'react';

function Loader(props) {

    // const displayLoader = useSelector(state => state[LoaderSliceName].displayLoader );
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '8px';
        
        return () => {
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        }
    }, [])

    return (
        <>
            <Backdrop
                sx={{ 
                    color: '#fff', 
                    zIndex: (theme) => theme.zIndex.drawer + 1000, 
                    // backgroundColor: 'rgba(0,0,0, 0.95)' 
                    // backgroundColor: 'transparent'
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

function Loading(props){
    let displayLoader = useSelector(state => state[LoaderSliceName].displayLoader );
    
    if(!displayLoader){
        displayLoader = !!props.fallback || !!props.show;
    }

    return ReactDOM.createPortal( displayLoader && <Loader />, document.getElementById('loader') );
}

export default Loading;